import { environment } from '@environment/environment';
import { parsePhoneNumber } from 'awesome-phonenumber';

export class PhoneUtil {
  public static toE164PhoneNumber(phoneNumber: string | undefined, countryCode?: string) {
    if (!phoneNumber) {
      return phoneNumber;
    }

    const parsedNumber = parsePhoneNumber(phoneNumber, {
      regionCode: countryCode ?? environment.defaultCountryCode,
    });

    return parsedNumber.valid ? parsedNumber.number.e164 : phoneNumber;
  }
}
