import { Component, ElementRef } from '@angular/core';
import { OrganizationStateService } from '@app/states/organization/organization-state.service';
import { Organization } from '@app/states/organization/organization.model';
import { PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { Observable } from 'rxjs';
import { ContactPopupComponent } from './contact-popup/contact-popup.component';
import { EtrackerService } from '@app/etracker/etracker.service';

@Component({
  selector: 'ruv-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: false,
})
export class FooterComponent {
  public organization$!: Observable<Organization | undefined>;

  private popupRef!: PopupRef | null;

  constructor(
    private popupService: PopupService,
    private eTracker: EtrackerService,
    stateService: OrganizationStateService,
  ) {
    this.organization$ = stateService.organization$;
  }

  public onOpenContactPopup(anchor: ElementRef<any>) {
    if (this.popupRef) {
      this.popupRef.close();
      this.popupRef = null;
    } else {
      this.popupRef = this.popupService.open({
        content: ContactPopupComponent,
        anchor: anchor,
        anchorAlign: {
          horizontal: 'center',
          vertical: 'top',
        },
        popupAlign: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        margin: { horizontal: 0, vertical: 30 },
      });
    }

    this.eTracker.sendClickEvent('Kontakt', 'info');
  }

  public onClickHyperlink(objectName: string) {
    this.eTracker.sendClickEvent(objectName, 'info');
  }
}
